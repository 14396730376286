import { createTheme } from '@mui/material/styles';
import { color } from './color';

const theme = createTheme({
  palette: {
    primary: {
      main: color.confin_new_blue_01,
      dark: color.confin_new_blue_01,
      contrastText: color.white,
    },
    secondary: {
      main: color.confin_indigo,
      dark: color.confin_gray_01,
      contrastText: color.white,
    },
    error: {
      main: color.confin_red_01,
      dark: color.confin_red_02,
      contrastText: color.white,
    },
    info: {
      main: color.confin_gray_02,
      dark: color.confin_gray_01,
      contrastText: color.white,
    },
  },
  typography: {
    fontFamily: ['Noto Sans Ko', 'Trebuchet MS'].join(','),
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: color?.confin_new_blue_01,
              borderRadius: '8px',
            },
            '& input.Mui-disabled': {
              background: color?.confin_gray_04,
              borderRadius: '8px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
            },

            // '&:hover fieldset': {
            //   borderColor: color?.confin_new_blue_01,
            //   borderRadius: '8px',
            // },
            ' fieldset': {
              borderRadius: '8px',
            },
          },
        },
      },
    },
  },
});

export default theme;
