import Player1 from './공병민1.jpg';
import Player2 from './김관욱2.jpg';
import Player3 from './김민석3.jpg';
import Player4 from './김성권4.jpg';
import Player5 from './김창수5.jpg';
import Player6 from './김현우6.jpg';
import Player7 from './류한수7.jpg';
import Player8 from './서주환8.jpg';
import Player9 from './신병철9.jpg';
import Player10 from './이세열10.jpg';
import Player11 from './정의현11.jpg';
import Player12 from './정한재12.jpg';
import Player13 from './박정애13.jpg';
import Player14 from './박현영14.jpg';
import Player15 from './이한빛15.jpg';
import Player16 from './정서연16.jpg';
import Player17 from './천미란17.jpg';
import Player18 from './오현영18.jpg';

import PlayBtn from './Polygon.png';
import PlayBtnSVG from './Polygon 1.svg';
import Wrestling from './wrestling.png';
import Wrestling2 from './wrestling2.png';
import Schedule from './schedule.png';
import MainGif from './Interview_MainSequence.gif';
import CheerSection from './태극기.png';
import Wadiz from './wadiz.png';
import Footer1 from './footer1.png';
import Footer2 from './footer2.png';
import Footer3 from './footer3.png';
import Typo from './typo.png';
import Logo from './logo.png';

export const playerImages = {
  Player1,
  Player2,
  Player3,
  Player4,
  Player5,
  Player6,
  Player7,
  Player8,
  Player9,
  Player10,
  Player11,
  Player12,
  Player13,
  Player14,
  Player15,
  Player16,
  Player17,
  Player18,
};

export const sectionImages = {
  PlayBtn,
  PlayBtnSVG,
  MainGif,
  Schedule,
  Wrestling,
  Wrestling2,
  Wadiz,
  CheerSection,
  Footer1,
  Typo,
  Logo,
  Footer2,
  Footer3,
};
