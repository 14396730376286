import React, { useEffect } from 'react';
import CopsPageContainer from '../components/CopsPageContainer';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import AsianImage from '../components/AsianImage';
import ShareIcon from '@mui/icons-material/Share';

import { playerImages, sectionImages } from '../images';

import { color } from '../elements';
import ReactPlayer from 'react-player';

const playerList = [
  {
    id: 1,
    name: '공병민',
    url: playerImages.Player1,
    link: 'https://m.sports-dive.com/players/profiles/782',
  },
  {
    id: 2,
    name: '김관욱',
    url: playerImages.Player2,
    link: 'https://m.sports-dive.com/players/profiles/787',
  },
  {
    id: 3,
    name: '김민석',
    url: playerImages.Player3,
    link: 'https://m.sports-dive.com/players/profiles/783',
  },
  {
    id: 4,
    name: '김성권',
    url: playerImages.Player4,
    link: 'https://m.sports-dive.com/players/profiles/793',
  },
  {
    id: 5,
    name: '김창수',
    url: playerImages.Player5,
    link: 'https://m.sports-dive.com/players/profiles/778',
  },
  {
    id: 6,
    name: '김현우',
    url: playerImages.Player6,
    link: 'https://m.sports-dive.com/players/profiles/772',
  },
  {
    id: 7,
    name: '류한수',
    url: playerImages.Player7,
    link: 'https://m.sports-dive.com/players/profiles/751',
  },
  {
    id: 8,
    name: '서주환',
    url: playerImages.Player8,
    link: 'https://m.sports-dive.com/players/profiles/795',
  },
  {
    id: 9,
    name: '신병철',
    url: playerImages.Player9,
    link: 'https://m.sports-dive.com/players/profiles/750',
  },
  {
    id: 10,
    name: '이세열',
    url: playerImages.Player10,
    link: 'https://m.sports-dive.com/players/profiles/784',
  },
  {
    id: 11,
    name: '정의현',
    url: playerImages.Player11,
    link: 'https://m.sports-dive.com/players/profiles/791',
  },
  {
    id: 12,
    name: '정한재',
    url: playerImages.Player12,
    link: 'https://m.sports-dive.com/players/profiles/777',
  },
  {
    id: 13,
    name: '박정애',
    url: playerImages.Player13,
    link: 'https://m.sports-dive.com/players/profiles/761',
  },
  {
    id: 14,
    name: '박현영',
    url: playerImages.Player14,
    link: 'https://m.sports-dive.com/players/profiles/754',
  },
  {
    id: 15,
    name: '이한빛',
    url: playerImages.Player15,
    link: 'https://m.sports-dive.com/players/profiles/760',
  },
  {
    id: 16,
    name: '정서연',
    url: playerImages.Player16,
    link: 'https://m.sports-dive.com/players/profiles/757',
  },
  {
    id: 17,
    name: '천미란',
    url: playerImages.Player17,
    link: 'https://m.sports-dive.com/players/profiles/758',
  },
  {
    id: 18,
    name: '오현영',
    url: playerImages.Player18,
    link: 'https://m.sports-dive.com/players/profiles/767',
  },
];
const sectionImagesList = Object.values(sectionImages);

const Home = () => {
  const goToSportdiveProfile = (link) => {
    window.location.href = link;
  };

  const hoverImgPreload = (images) => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  };
  const handleShareClick = async () => {
    // 공유 기능을 추가하는 로직을 여기에 추가하세요.
    const test = {
      title: '스포츠다이브',
      text: '스포츠다이브',
      url: window.location.href,
    };

    if (navigator.share) {
      navigator.share(test);
      await navigator.clipboard?.writeText(window.location.href);
    } else {
      await navigator.clipboard?.writeText(window.location.href);
      alert('링크가 공유되었습니다.');
    }
  };
  useEffect(() => {
    hoverImgPreload(sectionImagesList);
  }, []);
  return (
    <CopsPageContainer>
      {/* 메인섹션 */}

      <Box position={'relative'}>
        <Container
          disableGutters
          sx={{
            bgcolor: '#111',
            height: {
              lg: '670px',
              xs: `calc(56.25vw)`, // default
            },
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              top: 0,
              position: 'absolute',
              zIndex: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'top',
            }}
          >
            <Box
              sx={{
                py: 1,
                pl: 1,
                height: {
                  // xs: `100vh`, // default
                },
              }}
            >
              <AsianImage
                onClick={() => goToSportdiveProfile('https://sports-dive.com/')}
                src={sectionImages.Logo}
                width={'20%'}
                sx={{
                  cursor: 'pointer',
                  objectFit: 'cover',
                }}
              ></AsianImage>
            </Box>
          </Box>
          <Box
            sx={{
              height: {
                // xs: `100vh`, // default
              },
            }}
          >
            <AsianImage
              src={sectionImages.MainGif}
              width="100%"
              sx={{
                objectFit: 'cover',
              }}
            ></AsianImage>
          </Box>
        </Container>
      </Box>
      {/* 다음섹션 */}
      <Container disableGutters>
        {/* 응원메시지1 */}
        <Box>
          <Typography
            py={8}
            whiteSpace={'pre-line'}
            textAlign={'center'}
            color={color.confin_black}
            fontWeight={400}
            letterSpacing={-0.4}
            lineHeight={1.5}
            sx={{
              fontSize: { md: '22px', xs: '18px' },
            }}
          >
            {`경기장 안에서는 혼자 싸우지만\n 팬들과 함께한 시간이 더해지면\n 이제 함께가 됩니다.`}
          </Typography>
        </Box>

        {/* 대한민국레슬러 */}
        <Box
          sx={{
            height: {
              lg: '670px',
              xs: `calc(56.25vw)`, // default
            },
          }}
        >
          <AsianImage
            src={sectionImages.Typo}
            width="100%"
            sx={{
              objectFit: 'cover',
            }}
          ></AsianImage>
        </Box>

        {/* 응원메시지 */}
        <Box bgcolor={'#42BEAD'} py={8}>
          <Typography
            // py={8}
            whiteSpace={'pre-line'}
            textAlign={'center'}
            color={color.white}
            fontWeight={400}
            letterSpacing={-0.4}
            lineHeight={1.5}
            sx={{
              fontSize: { md: '22px', xs: '18px' },
            }}
          >
            {`팬 여러분의 뜨거운 성원에 힘입어 \n레슬링 국가대표팀을 응원할 수 있는`}
          </Typography>
          <Typography
            whiteSpace={'pre-line'}
            textAlign={'center'}
            color={color.white}
            fontWeight={400}
            letterSpacing={-0.4}
            lineHeight={1.5}
            sx={{
              fontSize: { md: '22px', xs: '18px' },
            }}
          >
            <Typography
              component={'span'}
              textAlign={'center'}
              color={color.white}
              fontWeight={700}
              letterSpacing={-0.4}
              lineHeight={1.5}
              sx={{
                fontSize: { md: '22px', xs: '18px' },
              }}
            >{`응원페이지`}</Typography>
            {`가 탄생했습니다 `}
          </Typography>
        </Box>
      </Container>
      {/* 유튜브 */}
      <Box>
        <ReactPlayer
          width={'100%'}
          url={'https://youtu.be/AMbhtC-i9AA'}
        ></ReactPlayer>
      </Box>

      {/* 선수이미지 */}
      <Grid container py={4}>
        {playerList.map((v) => {
          return (
            <Grid key={v.id} xs={4} item>
              <Stack p={2}>
                <AsianImage
                  onClick={() => goToSportdiveProfile(v.link)}
                  borderRadius={50}
                  bgcolor={'#111'}
                  src={v.url}
                  sx={{
                    objectFit: 'cover',
                  }}
                ></AsianImage>

                <Typography
                  mt={2}
                  mb={1}
                  fontWeight={700}
                  textAlign={'center'}
                  letterSpacing={-0.8}
                >
                  {v.name}
                </Typography>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Button
                    // fullWidth
                    onClick={() => goToSportdiveProfile(v.link)}
                    sx={{
                      letterSpacing: -0.8,
                      borderRadius: 20,
                      py: 1,
                      px: { xs: 2.5, md: 5 },
                      bgcolor: '#42BEAD',
                      '&:hover': {
                        bgcolor: '#318E89', // 마우스 호버 시 배경색 변경
                      },
                    }}
                    variant="contained"
                  >
                    응원하기
                  </Button>
                </Box>
              </Stack>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'center'} mt={8} mb={4}>
            <Button
              variant="outlined"
              onClick={() =>
                goToSportdiveProfile(
                  'https://sports-dive.com/players?category_id=0'
                )
              }
              sx={{
                py: 1,
                px: { xs: 8, md: 16 },
                letterSpacing: -0.8,
                fontSize: { xs: 16, md: 24 },
                borderRadius: 20,
                color: '#42BEAD',
                borderColor: '#42BEAD', // 버튼 border 색상
                '&:hover': {
                  borderColor: '#42BEAD',
                  bgcolor: '#42BEAD', // 마우스 호버 시 border 색상 변경
                  color: color.white, // 마우스 호버 시 텍스트 색상 변경 (선택 사항)
                },
              }}
            >
              다른 선수 바로가기
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* 레슬링이미지 */}
      <Box position={'relative'}>
        <Box
          sx={{
            height: {
              // xs: `100vh`, // default
            },
          }}
        >
          <AsianImage
            src={sectionImages.Wrestling}
            width="100%"
            height="100%"
            sx={{
              objectFit: 'cover',
            }}
          ></AsianImage>
        </Box>
      </Box>
      {/* 스케쥴섹션 */}
      <Box position={'relative'}>
        <Box
          sx={{
            height: {
              // xs: `100vh`, // default
            },
          }}
        >
          <AsianImage
            src={sectionImages.Schedule}
            width="100%"
            height="100%"
            sx={{
              objectFit: 'cover',
            }}
          ></AsianImage>
        </Box>
      </Box>
      {/* 레슬링2 */}
      <Box position={'relative'} mt={-1}>
        <Box
          sx={{
            height: {
              // xs: `100vh`, // default
            },
          }}
        >
          <AsianImage
            src={sectionImages.Wrestling2}
            width="100%"
            height="100%"
            sx={{
              objectFit: 'cover',
            }}
          ></AsianImage>
        </Box>
      </Box>
      <Stack
        bgcolor={'#41bfad'}
        color={color.white}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '100%',
            height: {
              // xs: `100vh`, // default
            },
          }}
        >
          <AsianImage
            src={sectionImages.CheerSection}
            width="100%"
            sx={{
              objectFit: 'cover',
            }}
          ></AsianImage>
        </Box>
        <Box bgcolor={'#41bfad'} px={'6.5%'} sx={{ letterSpacing: -0.3 }}>
          <Typography
            py={3}
            fontSize={22}
            fontWeight={700}
            sx={{ fontSize: { xs: 22, sm: 35, lg: 70 } }}
          >
            프로스펙스가 함께 응원합니다
          </Typography>
          <Typography sx={{ fontSize: { xs: 14, sm: 24, lg: 40 } }}>
            와디즈 사이트를 통해 레슬링 국가대표 응원 캠페인 펀딩에
          </Typography>
          <Typography sx={{ fontSize: { xs: 14, sm: 24, lg: 40 } }}>
            참여해주시면 프로스펙스가 멋진 리워드를 제공합니다.
          </Typography>
          <Typography
            pt={4}
            pb={3}
            sx={{ fontSize: { xs: 14, sm: 24, lg: 40 } }}
          >
            응모기간: 2023.09.20 ~ 2023.10.08
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'} my={5}>
          <Button
            variant="outlined"
            onClick={() =>
              goToSportdiveProfile(
                'https://www.wadiz.kr/web/campaign/detail/235158'
              )
            }
            sx={{
              py: 1,
              maxHeight: 200,
              px: { xs: 12, md: 16, lg: 24 },
              letterSpacing: -0.8,
              fontSize: { xs: 16, md: 24 },
              borderRadius: 20,
              color: '#42BEAD',
              bgcolor: color.white,
              borderColor: color.white, // 버튼 border 색상
              '&:hover': {
                borderColor: '#318E89',
                bgcolor: '#318E89', // 마우스 호버 시 border 색상 변경
                color: color.white, // 마우스 호버 시 텍스트 색상 변경 (선택 사항)
              },
            }}
          >
            펀딩 바로가기
          </Button>
        </Box>
      </Stack>
      {/* 와디즈 이미지 */}
      <Box
        sx={{
          bgcolor: '#42BEAD',
          height: {
            // xs: `100vh`, // default
          },
        }}
      >
        <AsianImage
          src={sectionImages.Wadiz}
          width="100%"
          sx={{
            objectFit: 'cover',
          }}
        ></AsianImage>
      </Box>
      {/* 푸터이미지1 */}
      <Box
        sx={{
          bgcolor: '#e7f5f3',
          height: {
            // xs: `100vh`, // default
          },
        }}
      >
        <AsianImage
          src={sectionImages.Footer1}
          width="100%"
          sx={{
            objectFit: 'cover',
          }}
        ></AsianImage>
      </Box>
      {/* 푸터이미지2 */}
      <Box
        sx={{
          height: {
            // xs: `100vh`, // default
          },
        }}
      >
        <AsianImage
          src={sectionImages.Footer2}
          width="100%"
          sx={{
            objectFit: 'cover',
          }}
        ></AsianImage>
      </Box>
      {/* 푸터이미지3 */}
      <Box
        sx={{
          cursor: 'pointer',
          height: {
            // xs: `100vh`, // default
          },
        }}
        onClick={() => goToSportdiveProfile('https://sports-dive.com/main')}
      >
        <AsianImage
          src={sectionImages.Footer3}
          width="100%"
          sx={{
            objectFit: 'cover',
          }}
        ></AsianImage>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleShareClick}
        sx={{
          height: 60,
          width: 50,
          borderRadius: '50%',
          position: 'fixed',
          backgroundColor: '#42BEAD', // 버튼 배경색
          color: '#ffffff', // 아이콘 색상을 흰색으로 설정합니다.

          bottom: '20px', // 화면 하단 여백 조절
          right: '20px', // 화면 우측 여백 조절
          zIndex: 1000, // 다른 요소 위에 나타나도록 설정
          '&:hover': {
            borderColor: '#318E89',
            bgcolor: '#318E89', // 마우스 호버 시 border 색상 변경
            color: color.white, // 마우스 호버 시 텍스트 색상 변경 (선택 사항)
          },
        }}
      >
        <ShareIcon />
      </Button>
    </CopsPageContainer>
  );
};

export default Home;
